//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';
import { useStoryblokBridge } from "@storyblok/nuxt-2";
import { getPathWithoutLocale } from '~/utils/helpers';
import seoHead from '~/mixins/seo-head';

export default {
  name: 'Pages_',
  mixins: [seoHead],
  async asyncData({ route, query, isDev, i18n, error, store }) {
    const { _storyblok } = query;
    const { locale, defaultLocale } = i18n;
    const isPreview = !!(_storyblok && _storyblok !== '') || isDev;
    const curPath = getPathWithoutLocale(route.path, locale, defaultLocale);
    const isHome = ['/', '/home'].includes(curPath);

    const path = isHome ? '/home' : curPath;

    if (!isPreview && path === '/layout-config') {
      error({ statusCode: 404, message: 'Not Found' });
    }

    try {
      const story = await store.dispatch('page/getPageStory', {
        path,
      });

      return {
        story,
        isHome,
      };
    } catch(err) {
      const response = typeof err === 'string' ? JSON.parse(err) : err;

      if (response?.status && response?.message) {
        error({ statusCode: response.status, message: response.message });
      } else {
        error({ statusCode: 404, message: 'Not Found' });
      }
    }
  },
  mounted() {
    if (this.story?.id) {
      useStoryblokBridge(this.story.id, (newStory) => {
        if (this.story.slug === 'layout-config') {
          this.setLayoutStory(newStory);
        }

        this.story = newStory;
        this.setPageStory(newStory);
      });
    }
  },
  methods: {
    ...mapMutations('layout', ['setLayoutStory']),
    ...mapMutations('page', ['setPageStory']),
  },
}
